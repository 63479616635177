
import apiService from '../../services/api.service'; // Import API service
import EditImagesRequestPage from '../editImage'


// export default EditImagesWarrantyPage;
const EditImagesWarrantyPage = () => {
  console.log('test')
return(
  <EditImagesRequestPage
  getRequest={apiService.getWarranty}
  updateRequest={apiService.updateWarrantyImagesAndStatus}
  uploadImages={apiService.uploadWarrantyImages}
  deleteImages={apiService.deleteWarrantyImages}
  requestType="warranty"
/>
)

}
export default EditImagesWarrantyPage