import brands from '../brands.json';

const BASE_URL = process.env.REACT_APP_API_URL;

async function fetchAPI(endpoint, method = 'GET', params = {},data = null) {
    console.log('data')
    const url = new URL(`${BASE_URL}${endpoint}`);

    let headers = {
        'x-access-token': process.env.REACT_APP_ACCESS_TOKEN,
        'x-merchant-id': brands[process.env.REACT_APP_BRAND].merchantId,
        'ngrok-skip-browser-warning': 'true'
         // token ? token : ''
    };

    if (method === 'GET' && params) {
        Object.keys(params).forEach(key => params[key] && url.searchParams.append(key, params[key]));
    }

    // Check if data is FormData and adjust headers and body accordingly
    if (!(data instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
        data = data ? JSON.stringify(data) : null;
    }

    const config = {
        method: method,
        headers: headers,
        body: method !== 'GET' ? data : null
    };

    try {
        const response = await fetch(url.toString(), config);
        if (!response.ok) {
            const errorMessage = await response.text(); // Retrieves text (including plain messages) from the response body
            console.error(errorMessage);
            throw new Error(errorMessage || 'يوجد خطأ في اتصال الانترنت حاول مجددا لاحقا'); // Throw an error with the server's response message
        }
        console.log(response)
        return response.json(); // Parses JSON response into native JavaScript objects
    } catch (error) {
          // Check for "Failed to fetch" error and replace it with a custom message
          if (error.message === 'Failed to fetch') {
            throw new Error('فشل الاتصال بالخادم. أعد المحاولة لاحقًا.');
        } else {
            console.error('خطأ:', error);
            throw error; // Re-throw other errors
        
        }
    }
}

const apiService = { // (endpoint, Method, params, data/body ) 
    sendOTP: (phoneNumber) => fetchAPI('/otp/sendOTP', 'POST', null,{ mobile: phoneNumber }),
    verifyOTP: (data) => fetchAPI('/otp/verifyOTP', 'POST', null,data),
    uploadReturnImages: (formData) => fetchAPI('/upload/salla.sites', 'POST',null, formData),
    createReturnRequest: (data) => fetchAPI('/returns', 'POST',null, data),
    getOrders: (phoneNumber, orderId, page) => fetchAPI('/orders', 'GET', { mobile: phoneNumber,orderId: orderId, page: page }),
    getReturns: (phoneNumber,orderId) => fetchAPI('/returns', 'GET', { mobile: phoneNumber,orderId: orderId }),
    getOrderById: (orderId) => fetchAPI(`/orders/${orderId}`, 'GET'),
    getWarrantyByOrderIdAndSku: (orderId, sku) => fetchAPI(`/warranties/${orderId}/${sku}`, 'GET'),
    getReturnByOrderIdAndSku:(orderId, sku) => fetchAPI(`/returns/${orderId}/${sku}`, 'GET'),
    createWarrantyRequest: (data) => fetchAPI('/warranties', 'POST',null, data),
    getWarrantiesByOrderAndMobile: (phoneNumber, orderId) => fetchAPI(`/warranties/${orderId}`, 'GET', { mobile: phoneNumber}),
    uploadWarrantyImages: (formData) => fetchAPI('/upload/sanint.warranty', 'POST',null, formData),
    deleteWarrantyImages: (fileKeys) => fetchAPI('/upload/sanint.warranty', 'DELETE', null, {fileKeys: fileKeys}),
    fetchSallaOrdersForCustomer: (phoneNumber,merchantId)=>fetchAPI('/ordersIntegeration','GET',{phoneNumber: phoneNumber, merchantId: merchantId}),
    fetchSallaOrderById:(merchantId, order_ref)=>fetchAPI(`/ordersSalla/`,'GET',{merchantId: merchantId, order_ref: order_ref}),
    warrantyCheck: (merchantId, orderId, checkAutomaticWatch, products) => fetchAPI('/warrantyCheck', 'POST', null,{ merchantId, orderId, checkAutomaticWatch, products }),
    getWarranty: (id) => fetchAPI(`/warranties/warranty/${id}`,'GET'),
    updateWarrantyImagesAndStatus: (id, productsUpdates) => fetchAPI(`/warranties/${id}/images`,'PATCH', null, {productsUpdates: productsUpdates}),
       // warranty with tokenized login
       validateOneTimeToken: (token) => fetchAPI('/one-time-token/validate-token', 'GET', {token: token}),
       validateMobileNumber: (token, mobile) => fetchAPI('/one-time-token/validate-mobile','POST', null, {token: token, mobile: mobile}),

    // Add more API functions here
};
export default apiService;