import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UserDataContext from '../session/UserDataContext'; 
import Loader from './../components/loader';
import { useErrorDialog } from './../global/errorContext';
import './../styles/orderDetails.css';
import './../styles/editImg.css';

const EditImagesRequestPage = ({ 
  getRequest, 
  updateRequest, 
  uploadImages, 
  deleteImages, 
  requestType // "warranty" or "return"
}) => {
  const { _id } = useParams();
  const { userData } = useContext(UserDataContext);
  const navigate = useNavigate();
  const { showError } = useErrorDialog();
  const [note, setNote ] = useState(null)
  const [requestItems, setRequestItems] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [previewMedia, setPreviewMedia] = useState(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [orderRef, setOrderRef] = useState();
  const keywords = ['صورة', 'فيديو'];
console.log('edit')
  useEffect(() => {
    const fetchRequest = async () => {
      setLoadingMessage('...جارٍ جلب بيانات الطلب');
      setIsLoading(true);

      try {
        // Get request data based on ID
        const request = await getRequest(_id);
        setNote(request.statusHistory[request.statusHistory.length - 1].note)
        setRequestItems(request.products);
        setOrderRef(request.order_ref);

        if (
            (["rejected", "review"].includes(request.status.slug)) && 
                      keywords.some(keyword => (request.statusHistory[request.statusHistory.length - 1]).note.includes((keyword)))
                      
          )          
            {

        }
        else{
          if(orderRef){
          showError('لابد من إتاحة تعديل الطلب من قبل فريق خدمة العملاء')
          console.log(orderRef)
          navigate(`/${requestType === "warranty" ? "warranties" : "returns"}/${orderRef}`);
          }
          else{
            throw new Error ('حدث خطأ أثناء جلب بيانات الطلب');
          }
        }
      } catch (error) {
        showError(error.message || 'حدث خطأ أثناء جلب بيانات الطلب');
        navigate(`/${requestType}/orders`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequest();
  }, [_id, userData.phoneNumber]); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingMessage('...تحديث الطلب');
    setIsLoading(true);

    try {
      setLoadingMessage('...جارِ حذف الصور الحالية');
      
      // Delete old images before uploading new ones
      for (let item of requestItems) {
        if (item.imageUrls.length) {
          const fileKeys = item.imageUrls.map(url => url.replace(/^https?:\/\/[^/]+\/[^/]+\//, ''));
          if (fileKeys.length) {
          await deleteImages(fileKeys);
            }
        }
      }
      const productsToUpdate = requestItems.filter(item => item.images.some(image => image instanceof File));

      setLoadingMessage('...جارِ رفع الصور / مقاطع الفيديو الجديدة');
      
      // Upload new images for selected items
      for (let item of requestItems) {
        if (item.images.length) {
          const formData = new FormData();
          const itemNameEncoded = encodeURIComponent(item.name); // Encoding item name for Arabic or special characters
          formData.append('itemName', itemNameEncoded); // Example: encoding Arabic text

          // Append images to form data
          item.images.forEach(image => formData.append('files', image));
          
          const response = await uploadImages(formData);
          item.imageUrls = response.data.map(file => file.url);
          console.log('Uploaded Image URLs:', item.imageUrls);
        }
      }

      // Update the request status and items
      setLoadingMessage('...جارِ تحديث حالة الطلب');
      await updateRequest(_id, productsToUpdate);
      navigate(`/thank-you${requestType === "warranty" ? "-warranty" : ""}`, { state: { orderRef } });

    } catch (error) {
      showError(error.message || 'حدث خطأ أثناء تحديث البيانات');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = (index, e) => {
    const MAX_TOTAL_SIZE = 9 * 1024 * 1024;
    const MAX_FILE_COUNT = 3;
    let uploadedImages = requestItems[index].images;

    const nonEmptyImagesCount = uploadedImages.filter(img => img instanceof File && img.name).length;

    if (nonEmptyImagesCount === 0) {
      setRequestItems(currentItems =>
        currentItems.map((item, itemIndex) =>
          index === itemIndex ? { ...item, images: [] } : item
        )
      );
      uploadedImages = [];
    }

    const newImages = Array.from(e.target.files).slice(0, MAX_FILE_COUNT);
    
    // Check if the total file size exceeds the limit
  const totalSize = newImages.reduce((sum, file) => sum + file.size, 0);

  if (totalSize > MAX_TOTAL_SIZE) {
    showError('حجم الملفات المرفوعة يتجاوز الحد المسموح به (9 ميجابايت)');
  } else if (nonEmptyImagesCount + newImages.length > MAX_FILE_COUNT) {
    showError('يمكنك فقط رفع ما يصل إلى 3 صور لكل منتج');
  } else {
    setRequestItems(currentItems =>
      currentItems.map((item, itemIndex) =>
        index === itemIndex ? { ...item, images: [...item.images, ...newImages] } : item
      )
    );
  }

    e.target.value = null;
  };

  const handleDeleteImage = (productIndex, imageIndex) => {
    const updatedRequestItems = requestItems.map((item, index) => {
      if (index === productIndex) {
        const updatedImages = item.images.filter((_, i) => i !== imageIndex);
        return { ...item, images: updatedImages };
      }
      return item;
    });
    setRequestItems(updatedRequestItems);
  };
  const handlePiPClick = (event) => {
    const videoElement = event.target;
  
    // Check if Picture-in-Picture is supported by the browser
    if (document.pictureInPictureEnabled) {
      // Request Picture-in-Picture mode
      if (videoElement !== document.pictureInPictureElement) {
        videoElement.requestPictureInPicture()
          .catch((error) => {
            console.error("Error requesting Picture-in-Picture:", error);
          });
      }
    } else {
      console.log('Picture-in-Picture is not supported in this browser.');
    }
  };
  const handlePreviewClick = (mediaUrl) => {
    if (previewMedia === mediaUrl) {
      setIsPreviewVisible(!isPreviewVisible); // Toggle visibility
    } else {
      setPreviewMedia(mediaUrl); // Set the media URL
      setIsPreviewVisible(true); // Show preview
    }
  };
  const getAcceptType = (note) => {
    console.log(note, " note")
    if (note?.includes('إرفاق صورة')) {
      console.log("image/*")
      return "image/*"};
    if (note?.includes('إرفاق مقطع فيديو')) {
      console.log("video/*")
      return "video/*"};
      console.log("image/*")
    return "image/*";
  };
  return (
    <div className="request-form">
      {isLoading ? (
        <Loader message={loadingMessage} />
      ) : (
        <form onSubmit={handleSubmit} className="order-form">
          {requestItems.map((product, index) => (
            <div key={product.id} className="product-row">
              <div className="product-item">
                <div className="product-details">
                  <img src={product.thumbnail} alt={product.name} className="product-image" />
                  <div className="text-details">
                    <p className="product-title">{product.name} {product.sku}</p>
                    <p className="product-price">{product.price} رس</p>
                    {product.options?.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <p className="product-options">{option?.name}: {option?.value?.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
                
                  {/* Preview for already uploaded images (imageUrls) */}
                  <div className="selections">
                  <div className="current-image-container">
                    <h4 className="current-images-title">صور الطلب الحالية</h4>
                    <div className="image-preview-border">
                    {requestItems[index].imageUrls?.map((mediaUrl, imgUrlIndex) => {
                          // Extract file extension from the URL (e.g., '.jpg', '.mp4')
                          const fileExtension = mediaUrl.split('.').pop()?.toLowerCase();

                          // Check if the file is an image (jpg, png, jpeg, gif)
                          if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                            return (
                              <div key={imgUrlIndex} className="image-preview">
                                <img
                                src={mediaUrl}
                                alt={`Uploaded Image Preview ${imgUrlIndex}`}
                                onClick={() => handlePreviewClick(mediaUrl)} // Handle click
                              />
                              {previewMedia === mediaUrl && isPreviewVisible && (
                                <div className="pip-preview-container">
                                  <div class="img-overlay"></div>
                                  <button
                                    className="pip-preview-close"
                                    onClick={() => setIsPreviewVisible(false)} // Close button hides the preview
                                  >
                                    X
                                  </button>
                                <img
                                  src={mediaUrl}
                                  alt="Large Preview"
                                  
                                />
                                </div>
                              )}
                              </div>
                            );
                          }
                          // Check if the file is a video (mp4, avi, mkv)
                          else if (['mp4', 'avi', 'mkv'].includes(fileExtension)) {
                            return (
                              <div key={imgUrlIndex} className="image-preview">
                                <video onClick={handlePiPClick}>
                                  <source src={mediaUrl} type={`video/${fileExtension}`} />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            );
                          }
                          return null; // If it's neither an image nor video, return null
                        })}
                    </div>
                  </div>
                </div>
                
                <div className="selections">
                <h4>صور الطلب الجديدة</h4>
                  <div className="browse-img">
                    <p className="required-field">صور المنتج على الطبيعة</p>
                    <input
                      type="file"
                      multiple
                      id={`image-upload-${index}`}
                      style={{ display: 'none' }}
                      onChange={(e) => handleImageUpload(index, e)}
                      accept={getAcceptType(note)}
                    />
                    <button
                      type="button"
                      onClick={
                        () => {
                          document.getElementById(`image-upload-${index}`).click()
                        }}
                    >
                      استعراض مكتبة الصور ومقاطع الفيديو
                    </button>
  
                    {/* Preview for newly selected images (from file input) */}
                    <div className="image-preview-container">
                      {requestItems[index].images?.map((mediaFile, imgIndex) => {
                         
                          
                        if (mediaFile instanceof File) {
                          if (mediaFile.type.startsWith('image/')) {

                          return (
                            <div key={imgIndex} className="image-preview">
                              <img src={URL.createObjectURL(mediaFile)} alt={`New Preview ${imgIndex}`} />
                              <button
                                type="button"
                                className="delete-image-btn"
                                onClick={() => handleDeleteImage(index, imgIndex, 'file')}
                              >
                                X
                              </button>
                            </div>
                          );
                        }
                        else if (mediaFile.type.startsWith('video/')) {
                          return (
                            <div key={imgIndex} className="image-preview">
                              <video controls>
                                <source src={URL.createObjectURL(mediaFile)} type={mediaFile.type} />
                                Your browser does not support the video tag.
                              </video>
                              <button
                                type="button"
                                className="delete-image-btn"
                                onClick={() => handleDeleteImage(index, imgIndex , 'file')}
                              >
                                X
                              </button>
                            </div>
                          );
                        }
                        }
                        return null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
  
          <button className="submit-return-request" type="submit">
            تعديل الطلب
          </button>
        </form>
      )}
    </div>
  );
  
  
  
  
  

};


export default EditImagesRequestPage;
