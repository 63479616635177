import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';

const Header = () => {
  const location = useLocation();
  let heading;
  let link = '';


  // Define headings and links based on the current path
  if (location.pathname.toLowerCase().includes('warranty') || location.pathname.includes('warranties')) {
    heading = 'منصة الضمان';
    link = '/warranty/orders';
  } else if (location.pathname === '/') {
    heading = 'منصة الإرجاع والضمان';
    link = '/';
  } else {
    heading = 'منصة إرجاع الطلبات';
    link = 'return/orders';
  }

  // Update document title when heading changes
  useEffect(() => {
    document.title = heading;
  }, [heading]);


  return (
    <h3>
        {heading}
      
    </h3>
  );
};

export default Header;
