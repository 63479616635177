import { Link, useNavigate } from 'react-router-dom';
import UserDataContext from '../../session/UserDataContext'; // Import the context
import React, { useEffect, useState, useContext } from 'react';
import apiService from '../../services/api.service';
import { useErrorDialog } from '../../global/errorContext';
import Loader from '../../components/loader';
import '../../styles/orders.css'; // Import the CSS module

const OrdersPage = () => {
    // to navigate to orders list page ...
    const navigate = useNavigate();
    const { showError } = useErrorDialog();
    const { userData } = useContext(UserDataContext); // Access userData from context
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // For pagination
    const [totalPages, setTotalPages] = useState(1);  // Total number of pages

    // Options for the toLocaleDateString function to format the date as day, date, month, and year
    const options = {
        weekday: 'long', // long representation for the day
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        calendar: 'gregory', // ensure use of the Gregorian calendar
        numberingSystem: 'latn' // use Latin numerals instead of Arabic numerals
    };

    useEffect(() => {
        const fetchOrders = async (page = 1, orderId = '') => {
            setIsLoading(true); // Start loading

            try {
                const orders = await apiService.getOrders(userData.phoneNumber, orderId,page);
                setOrders(orders?.orders);
                setTotalPages(orders?.pagination?.totalPages);  // Update total pages
                // sessionStorage.setItem('orders', JSON.stringify(orders));
            } catch (error) {
                showError(error.toString());
            } finally {
                setIsLoading(false); // end loading
            }
        };
        if (userData.phoneNumber) {
            const savedOrders = null //sessionStorage.getItem('orders');
            if (savedOrders) {
                try {
                    const orders = JSON.parse(savedOrders); // Parse the string back into JSON
                    setOrders(orders);
                } catch (error) {
                    console.error("Failed to parse orders from sessionStorage:", error);
                    fetchOrders(currentPage); // If parsing fails, fetch orders from API
                }
            } else { // if no stored orders
                fetchOrders(currentPage);
            }
        } else {
            // Handle case where mobile is not provided, e.g., redirect
            navigate('/');
        }
    }, [userData.phoneNumber, currentPage]);

    const isWithinLastThreeDays = (deliveredAt) => {
        const today = new Date();
        const deliveryDate = new Date(deliveredAt);

        // Set both dates to the beginning of the day
        today.setHours(0, 0, 0, 0);
        deliveryDate.setHours(0, 0, 0, 0);

        // Add one day to the delivery date to exclude the delivery date itself from the count
        deliveryDate.setDate(deliveryDate.getDate() + 1);


        // Calculate the difference in days
        const diffTime = today - deliveryDate;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);  // Convert milliseconds to days

        return diffDays < 14;
    };

    function isReturnable(order) {
        //  return order.delivered_at
        //     ? order.status.slug !== 'restored' && order.status.slug !== 'restoring' && order.status.slug !== 'canceled'
        //     && !order.hasReturn
        //     && isWithinLastThreeDays(order.delivered_at)
        //     : false;
            
        if (!order.delivered_at) {
            // If there's no delivery date, check the other conditions
            return order.status.slug !== 'restored' && order.status.slug !== 'restoring' && order.status.slug !== 'canceled'
                && !order.hasReturn;
        } else {
           // If there's a delivery date, check all conditions including isWithinLastThreeDays
            return order.status.slug !== 'restored' && order.status.slug !== 'restoring' && order.status.slug !== 'canceled'
                && !order.hasReturn
                && isWithinLastThreeDays(order.delivered_at);
       }
    }

     // Handle changing pages
     const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className='ordersPage'>
            {isLoading ? (
                <Loader /> // Your loader here. Could be a spinner or custom loader component.
            ) : (
                <>
                    <div className='banner'>
                        {/*<img src={zyros_logo} className='logo' />*/}
                    </div>
                    <h1 className='title'>الطلبات</h1>
                    {/* User info should probably come from userData, hardcoded for example */}
                    <div className='userInfo'>
                        {/*  <img src="/path-to-user-avatar.jpg" alt="User Avatar" className={styles.avatar} />*/}
                    </div>
                    <div className='ordersTableContainer'>
                        {orders && orders.length > 0 ? (
                            <>
                                <table className="s-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">رقم الطلب</th>
                                            <th scope="col">المجموع</th>
                                            <th scope="col">تاريخ الطلب</th>
                                            <th scope="col">حالة الطلب</th>
                                            <th scope="col">تاريخ التوصيل</th>
                                            <th scope="col">مدة الاسترجاع 14 يوم</th>
                                            <th scope="col">سجل طلبات الاسترجاع</th>
                                            <th scope="col">الاسترجاع</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list-container">
                                        {orders.map((order, index) => (
                                            <tr key={index} className="s-table__tr text-store-text-primary">
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">رقم الطلب:</span>
                                                        #{order.order_ref}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">المجموع:</span>
                                                        <div className="total" >{order.total} SAR</div>
                                                        {/*  <a href={order.link}></a>*/}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">تاريخ الطلب:</span>
                                                        {new Date(order.created_at).toLocaleDateString('ar-EG', options)}
                                                        {/* <a href={order.link}></a>*/}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">حالة الطلب:</span>
                                                        <span className="rtl:mr-auto ltr:ml-auto md:mx-0" style={{ color: "#111111" }}>
                                                            {/* <i className={`mt-1 ${order.statusIcon}`}></i>*/}
                                                            <span>{order.status.name}</span>
                                                        </span>
                                                        {/*  <i className={`sicon-star2 text-lg rtl:mr-4 ltr:ml-4 ${order.starColor}`}></i>*/}
                                                        {/* <a href={order.link}></a>*/}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">تاريخ التوصيل:</span>
                                                        {order.delivered_at ? (new Date(order.delivered_at).toLocaleDateString('ar-EG', options)) : '-'}
                                                        {/* <a href={order.link}></a>*/}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">مدة الاسترجاع خلال 14 يوم:</span>
                                                        {order.delivered_at ? (isWithinLastThreeDays(order.delivered_at) ? 'نعم' : 'تجاوزت مدة الاسترجاع ') : '-'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">سجل طلبات الاسترجاع:</span>
                                                        <Link to={`/returns/${order.order_ref}`}>تتبع الإرجاع</Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button className='return-button' disabled={!isReturnable(order)}
                                                            onClick={() => navigate(`/order/${order.order_ref}`)}>
                                                            {isReturnable(order) ? 'بدء الارجاع' : 'غير متاح'}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                 {/* Pagination Controls */}
                                 <div className="pagination">
                                    <button
                                        className="pagination-button"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        السابق
                                    </button>
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <button
                                            key={i + 1}
                                            className={`pagination-button ${i + 1 === currentPage ? 'active' : ''}`}
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    ))}
                                    <button
                                        className="pagination-button"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        التالي
                                    </button>
                                </div>
                                <p> الارجاع يصبح متاح خلال 6 إلى 12 ساعة من وقت استلام الطلب </p>

                            </>
                            
                        ) : (
                            <p>لايوجد طلبات</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default OrdersPage;